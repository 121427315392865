import React, { useEffect, useState } from 'react';
import { GenericTabs } from '../components/Tabs';
import { ITabs } from '../components/Tabs/types';
import Rewrite from 'types/Rewrite';
import { DictionaryItem } from '../types/Dictionary';

export const TabsContainer = ({
  tabs,
  copy,
  PageComponentMapper,
  rewrites,
  apiUrl,
}: {
  tabs: ITabs;
  copy: DictionaryItem[];
  PageComponentMapper: any;
  rewrites: Record<string, Rewrite>;
  apiUrl: string;
}) => {
  const [selectedTab, setSelectedTab] = useState(0);
  useEffect(() => {
  }, [selectedTab]);
  const selectedItem: any = tabs.tabs?.[selectedTab];
  return (
    <GenericTabs setSelectedTab={setSelectedTab} tabs={tabs}>
      <PageComponentMapper
        components={selectedItem.components}
        copy={copy}
        data-test={selectedItem.tabTitle}
        rewrites={rewrites}
        apiUrl={apiUrl}
      />
    </GenericTabs>
  );
};
