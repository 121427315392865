import React, { ReactNode, useEffect } from 'react';
import { Tab, TabList, Tabs } from 'react-tabs';
import ScrollContainer from 'react-indiana-drag-scroll';
import { CustomTab, Heading, SeparatorContainer, SeparatorDot, StyledTabDiv } from './styles';
import { ITabs } from './types';

export function GenericTabs({
  id = 'authorable-tabs',
  children,
  tabs,
  setSelectedTab,
}: {
  id?: string;
  children: ReactNode;
  tabs: ITabs;
  setSelectedTab: Function;
}) {
  const allTabs = tabs.tabs?.map((item: any, index: number) => (
    <React.Fragment key={`tabs-${index}`}>
      <Tab key={item.tabTitle} onClick={() => setSelectedTab(index)}>
        <Heading
          itemProp="servingSize"
          tag="div"
          type={tabs.tabs && tabs.tabs.length > 3 ? 'xxs' : 'xs'}
        >
          {item.tabTitle}
        </Heading>
      </Tab>
      {tabs.tabs && index !== tabs.tabs.length - 1 && (
        <SeparatorContainer>
          <SeparatorDot />
        </SeparatorContainer>
      )}
    </React.Fragment>
  ));

  useEffect(() => {
    let tabListContainer: any = document.getElementById(`tabList-container${tabs.cmsTabTitle}`);
    tabListContainer = tabListContainer.getBoundingClientRect();
    let childrenContainer: any = document.getElementById(`children-container${tabs.cmsTabTitle}`);
    childrenContainer = childrenContainer.getBoundingClientRect();
    if (childrenContainer.top < tabListContainer.top + 80) {
      document.getElementById(`children-container${tabs.cmsTabTitle}`)?.classList.add('mt-16');
    }
  }, []);

  return (
    <CustomTab data-test={`${id}-authorable-tabs`}>
      <Tabs>
        <StyledTabDiv
          className="w-full overflow-y-auto bg-offWhite z-22"
          id={`tabList-container${tabs.cmsTabTitle}`}
        >
          <ScrollContainer className="scroll-container">
            <TabList data-test={`${id}-all-tabs-list`}>{allTabs}</TabList>
          </ScrollContainer>
        </StyledTabDiv>
        <div className="children-container" id={`children-container${tabs.cmsTabTitle}`}>
          {children}
        </div>
      </Tabs>
    </CustomTab>
  );
}

GenericTabs.defaultProps = {
  id: 'authorable-tabs',
};
